import React, {useEffect, useRef} from "react";
import { IntlProvider } from "react-intl";
import it from '../../locale/it.json';
import en from '../../locale/en.json';
import { useCookies } from 'react-cookie';


export const Context = React.createContext();

export const IntlProviderWrapper = (props) => {
    const [LocCookie, setLocCookie] = useCookies(['language']);
    const date_expires = new Date();
    date_expires.setDate(date_expires.getDate() + 999);
    let language_cookie;
    const refRender = useRef(true)

    const setCookie = (lang) => {
        if (lang === 'it-IT' || lang ==='it') {
            setLocCookie('language', 'it', { path: "/", expires: date_expires})
        } else {
                setLocCookie('language', 'en', { path: "/", expires: date_expires})
            }
        window.location.reload()
    }

    useEffect(() =>{
        refRender.current = false
    },[])


    if(LocCookie.language === undefined) {
        if (refRender.current){
            const locale = navigator.language;
            if (locale === 'it-IT' || locale ==='it') {
                language_cookie = 'it'
            }  else {
                    language_cookie = 'en'
                }
        }
    }
    else {
        language_cookie = LocCookie.language
    }
    const contextValue={
        
       language_cookie:language_cookie
    }

    return (
        <Context.Provider value={{ language_cookie, setCookie }}>
            <IntlProvider   messages={language_cookie === 'it' ? it : en} locale={language_cookie} defaultLocale={language_cookie}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    );

 
}
