import React, { useState } from "react";
const ComponentsContext = React.createContext({
  avaiableComponents: [],
  nameOfComponents: [],
  // isLoggedIn:false,
  componentSetter: (components) => {},
  namesSetter: (components) => {},
});

export const ComponentsContextProvider = (props) => {
    
  const [nameOfComponents, setNameOfComponents] = useState([]);
  const initialComponents=JSON.parse(localStorage.getItem('components'))
  const [components, setComponents] = useState(initialComponents);
  ////////////////////////////////////////////////////////////////


  ///////////////////////////////////////////////////////////////
  const setterHandler = (components) => {
    setComponents(components);
    console.log("COMPONENTI"+components);

    localStorage.setItem("components", components);
  };

  const contextValue = {
    avaiableComponents: components,
    componentSetter: setterHandler,
    nameOfComponents: nameOfComponents,
   
  };

  return (
    <ComponentsContext.Provider value={contextValue}>
      {props.children}
    </ComponentsContext.Provider>
  );
};
export default ComponentsContext;
