import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect, useContext  } from 'react'
import { default as Select } from "react-select";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import AuthContext from "../store/auth-context";
import ComponentsContext from "../store/components-context";
import {faCube, faColumns, faBook,faCalendar} from "@fortawesome/free-solid-svg-icons/"

const DinamicRepeaterMenu = props => {
    const [active, setActive] = useState(false);
	const compCtx = useContext(ComponentsContext);
	const authCtx = useContext(AuthContext);
	const profile=localStorage.getItem('profile');
	var ssidoperatore=localStorage.getItem('ssidoperatore');
  const updateMenu = (index) => {
    props.updateMenu(index);
  };
	if(props.value[0] && props.value[0]!=null && props.value[0]!=undefined && ((props.value[0]!="undefined" && !props.value[0].includes("edit") || props.value[0]==="/circolo-edit" || (ssidoperatore!=null && ssidoperatore.length>0 && props.value[0]==="/operatore-edit")) && !props.value[0].includes("add"))){
    return (
        <React.Fragment>
                  <li onClick={()=>{updateMenu(props.index+props.submenu)}} >
                    <Link to={props.value[0]} className={props.indexactive!=="" && props.index+props.submenu===props.indexactive ? 'active' : ''} style={{paddingLeft:(props.submenu == 'golf' ? '20px' : '')}}>
{props.value[0]. slice(1, props.value[0].length)=="operatore-edit"?<i className="fa fa-user fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="operatori-elenco"?<i className="fa fa-user fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="tabella-pacchetti"?<i className="fa fa-cube fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="pacchetti-dinamici"?<i className="fa fa-cube fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="buy-greenfee"?<i className="fa fa-golf-ball fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="resell-dynamic-packages"?<i className="fa fa-cubes fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="anagrafica-touroperator"?<i className="fa fa-user fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="servizi-elenco"?<i className="fa fa-indent fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="tabella-strutture"?<i className="fa fa-columns fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="tabella-prenotazioniservizi"?<i className="fa fa-calendar-alt fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="tabella-prenotazionivacanze"?<i className="fa fa-book fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="leggi-qrcode"?<i className="fa fa-qrcode fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="statistiche-circolo"?<i className="fa fa-bar-chart fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="statistiche-circoli"?<i className="fa fa-bar-chart fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="gare"?<i className="fa fa-trophy fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="circolo-edit"?<i className="fa fa-golf-ball fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="circoli"?<i className="fa fa-golf-ball fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="soci"?<i className="fa fa-user fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="percorsi"?<i className="fa fa-route fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="tabella-prezzi"?<i className="fa fa-eur fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="regole-prezzi"?<i className="fas fa-chart-line fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="importa-soci"?<i className="fa fa-file-import fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="prodotti"?<i className="fa fa-product-hunt fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="tipologia-giocatori"?<i className="fa fa-list-alt fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="calendario-circolo"?<i className="fa fa-calendar-alt fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="crea-utente"?<i className="fa fa-user-plus fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="lista-utenti"?<i className="fa fa-users fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="servizi-circolo"?<i className="fa fa-luggage-cart fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="statistiche"?<i className="fa fa-bar-chart fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="cms-pages"?<i className="fas fa-file fa-lg text-white"></i>:""}
{props.value[0]. slice(1, props.value[0].length)=="cms-news"?<i className="fa fa-newspaper fa-lg text-white"></i>:""}
<span><FormattedMessage id={props.value[0]. slice(1, props.value[0].length)}/></span></Link>
                  </li>
        </React.Fragment>
    )
   }else{
	    return (
        <>
        </>
    )
	}
}
export default DinamicRepeaterMenu