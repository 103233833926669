import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

const AuthContext = React.createContext({
  token: "",
  session_token: "",
  profiletype: "",
  configurations: [{}],
  isLoggedIn: false,
  login: (token, session_token) => {},
  setprofile: (profiletype) => {},
  setidoperatore: () => {},
  setidtouroperator: () => {},
  setslugcampo: () => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem("token");
  const initialsessionToken = localStorage.getItem("session_token");
  const [token, setToken] = useState(initialToken);
  const [sessiontoken, setSessionToken] = useState(initialsessionToken);
  const [profiletype, setProfiletype] = useState();
  const [ssidoperatore, setSSIDOperatore] = useState();
  const [operatorcancreateservices, setOperatorcancreateservices] =
    useState(false);
  const [operatorcansell, setOperatorcansell] = useState(false);
  const [slugcampo, setSlugcampo] = useState();
  const [configurations, setConfigurations] = useState([{}]);
  const [configurationsloaded, setConfigurationsloaded] = useState(false);
  const [operatoreloaded, setOperatoreloaded] = useState(false);
  const [golfloaded, setGolfloaded] = useState(false);
  const userIsLoggedIn = !!token;
  const theapipath = "https://api.bestime.golf";

  const loginHandler = (token, session_token) => {
    setToken(token);
    setSessionToken(session_token);
    console.log("session_token " + session_token);
    localStorage.setItem("token", token);
    localStorage.setItem("session_token", session_token);
  };
  const profileHandler = (idprofile) => {
    setProfiletype(idprofile);
    localStorage.setItem("idprofile", idprofile);
  };
  const operatoreHandler = (ssidoperatore) => {
    setSSIDOperatore(ssidoperatore);
    localStorage.setItem("ssidoperatore", ssidoperatore);
  };
  const logoutHandler = () => {
    setToken(null);
    setSessionToken(null);
    localStorage.clear();
  };
  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    setProfile: profileHandler,
    logout: logoutHandler,
  };

  useEffect(() => {
    var config = {
      method: "get",
      url: theapipath + "/acl/configurations",
      headers: {
        Bearer: "63H69G334867H348346G",
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log("CONFIGURAZIONI " + response.data);
        setConfigurations(response.data.data);
        localStorage.setItem("configurations", JSON.stringify(response.data.data));
        setConfigurationsloaded(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  

  useEffect(() => {
    if (operatoreloaded) {
      console.log("ssidoperatore" + ssidoperatore);
      localStorage.setItem("ssidoperatore", ssidoperatore);
    }
  }, [operatoreloaded, ssidoperatore]);

  useEffect(() => {
    if (token) {
      console.log(token);
      var data = JSON.stringify({
        access_token: token,
        tabella: "tur_operatori",
      });
      var config = {
        method: "post",
        url: theapipath + "/acl/userelements",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          console.log(
            "OPERATORI ASSOCIATI " + JSON.stringify(response.data.data)
          );
          if (response.data.data.length === 1) {
            setProfiletype(2);
            localStorage.setItem("idprofile", 2);
            setSSIDOperatore(response.data.data[0].ssid_element);
            localStorage.setItem("ssidoperatore", response.data.data[0].ssid_element);
            //alert("cerco operatore")
            var config = {
              method: "get",
              url:
                theapipath +
                "/turismoadmin/operatore/" +
                response.data.data[0].ssid_element,
              headers: {
                Bearer: "63H69G334867H348346G",
                "Content-Type": "application/json",
              },
            };
            axios(config)
              .then(function (response) {
                setOperatorcancreateservices(
                  response.data.data.creaservizi === "SI" ? true : false
                );
                setOperatorcansell(
                  response.data.data.vendeservizi === "SI" ? true : false
                );
                localStorage.setItem(
                  "operatorcancreateservices",
                  response.data.data.creaservizi === "SI" ? "true" : "false"
                );
                localStorage.setItem(
                  "operatorcansellservices",
                  response.data.data.vendeservizi === "SI" ? "true" : "false"
                );
              })
              .catch(function (error) {
                console.log(error);
              });
          }
          setOperatoreloaded(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (token && operatoreloaded === true) {
      var data = JSON.stringify({
        access_token: token,
        tabella: "circolo",
      });
      alert("CERCO CAMPO");
      var config = {
        method: "post",
        url: theapipath + "/acl/userelements",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          alert("CIRCOLI ASSOCIATI " + response.data.data);
          if (response.data.data.length === 1) {
            setProfiletype(6);
            localStorage.setItem("idprofile", 6);
            setSlugcampo(response.data.data[0].ssid_element);
            console.log(
              "auth: slug campo " + response.data.data[0].ssid_element
            );
            localStorage.setItem(
              "slugcampo",
              JSON.stringify(response.data.data[0].ssid_element).replaceAll(
                '"',
                ""
              )
            );
          }
          setGolfloaded(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
