import App from ".";
import { useContext } from "react";
import reportWebVitals from "./reportWebVitals";
import React, { Suspense, lazy } from "react";
import "./components/css/datatables.min.css";
import "./components/style/bootstrap.css";
import "./components/style/bootstrap-grid.css";
import "./components/style/mysass.scss";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Wrapper from "./components/common/wrapper";
import CircularProgress from "@mui/material/CircularProgress";
import AuthContext, {
  AuthContextProvider,
} from "./components/store/auth-context";
import FakeHeader from "./components/common/locale/FakeHeader";
import { ComponentsContextProvider } from "./components/store/components-context";
import { IntlProviderWrapper } from "./components/store/intl-context";
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
////////////////////////////////////////////////////////////////////////////////////
const Root = () => {
  const authCtx = useContext(AuthContext);

  console.log("loggato?", authCtx.isLoggedIn);
  const Index = lazy(() => import("./components/Index"));
  const ManagePage = lazy(() => import("./components/managepage"));
  const LoginPage = lazy(() => import("./components/LoginPage"));
  const PasswordReset = lazy(() =>
    import("./components/elements/PasswordReset")
  );
  const RegistrationPage = lazy(() => import("./components/RegistrationPage"));
  const WorkingPage = lazy(() => import("./components/WorkingPage"));
  const DummyTablePage = lazy(() => import("./components/DummyTablePage"));

  const GarePage = lazy(() => import("./components/Circolo/Gare/GarePage"));
  const GaraEdit = lazy(() => import("./components/Circolo/Gare/GaraEdit"));

  const ServizioCircoloPage = lazy(() =>
    import("./components/Circolo/Servizi/ServizioCircoloPage")
  );
  const ServizioCircoloEdit = lazy(() =>
    import("./components/Circolo/Servizi/ServizioCircoloEdit")
  );

  const PacchettiDinamiciDetailsEdit = lazy(() =>
    import(
      "./components/Pacchetti Vacanze/PacchettiDinamici/PacchettiDinamiciDetailsEdit"
    )
  );
  const PacchettiDinamiciPage = lazy(() =>
    import(
      "./components/Pacchetti Vacanze/PacchettiDinamici/PacchettiDinamiciPage"
    )
  );
  const ReselledDynamicPackages = lazy(() =>
    import(
      "./components/Pacchetti Vacanze/PacchettiDinamici/ReselledDynamicPackages"
    )
  );
  
  const PacchettiDinamiciResellPage = lazy(()=>import("./components/Pacchetti Vacanze/PacchettiDinamici/PacchettiDinamiciResellPage"));


  const PacchettiDinamiciAdesioniPage = lazy(() =>
    import(
      "./components/Pacchetti Vacanze/PacchettiDinamici/PacchettiDinamiciAdesioniPage"
    )
  );
  const PacchettiDinamiciAdesioniEdit = lazy(() =>
    import(
      "./components/Pacchetti Vacanze/PacchettiDinamici/PacchettiDinamiciAdesioniEdit"
    )
  );

  const Soci = lazy(() => import("./components/Circolo/Soci/Soci"));
  const ImportaSoci = lazy(() =>
    import("./components/Circolo/Soci/ImportaSoci")
  );
  const SociEdit = lazy(() => import("./components/Circolo/Soci/SociEdit"));
  const SociAdd = lazy(() => import("./components/Circolo/Soci/SociAdd"));

  const EditCircolo = lazy(() => import("./components/Circolo/EditCircolo"));

  const Circoli = lazy(() => import("./components/Circolo/Circoli/Circoli"));
  const CircoloDetailsEdit = lazy(() =>
    import("./components/Circolo/Circoli/CircoloDetailsEdit")
  );
  const LoginAs = lazy(() => import("./components/Circolo/Circoli/LoginAs"));

  const StatisticheCircolo = lazy(() =>
    import("./components/Circolo/StatisticheCircolo")
  );

  const PrenotaGreenFee = lazy(() =>
    import("./components/PrenotaGreenFee/PrenotaGreenFee")
  );


  const PercorsoCreate = lazy(() =>
    import("./components/Circolo/Percorsi/PercorsiAdd")
  );
  const Circolo = lazy(() => import("./components/Circolo/SchedaCircolo"));

  const Prodotti = lazy(() => import("./components/Circolo/Prodotti/Prodotti"));
  const TipologiaGiocatori = lazy(() =>
    import("./components/Circolo/TipologiaGiocatori/TipologiaGiocatori")
  );
  const Percorsi = lazy(() => import("./components/Circolo/Percorsi/Percorsi"));
  const PercorsoEdit = lazy(() =>
    import("./components/Circolo/Percorsi/PercorsoEdit")
  );
  const ProdottoEdit = lazy(() =>
    import("./components/Circolo/Prodotti/ProdottoEdit")
  );
  const ProdottoAdd = lazy(() =>
    import("./components/Circolo/Prodotti/ProdottoAdd")
  );
  const CalendarioCircolo = lazy(() =>
    import("./components/Circolo/CalendarioCircolo/CalendarioCircolo")
  );


  const CalendarioNuovo = lazy(() =>
    import("./components/Circolo/CalendarioCircolo/CalendarioNuovo")
  );

  const CMSNewsPage = lazy(() => import("./components/Cms/News/CMSNewsPage"));
  const CMSNewsEdit = lazy(() => import("./components/Cms/News/CMSNewsEdit"));

  const CMSPagesPage = lazy(() =>
    import("./components/Cms/Pagine/CMSPagesPage")
  );
  const CMSPagesEdit = lazy(() =>
    import("./components/Cms/Pagine/CMSPagesEdit")
  );

  const TipologiaEdit = lazy(() =>
    import("./components/Circolo/TipologiaGiocatori/TipologiaEdit")
  );
  const TipologiaAdd = lazy(() =>
    import("./components/Circolo/TipologiaGiocatori/TipologiaAdd")
  );
  const PrezziDinamici = lazy(() =>
    import("./components/Circolo/RegolePrezziDinamici/RegolePrezziDinamici")
  );
  const RegolePrezziEdit = lazy(() =>
    import("./components/Circolo/RegolePrezziDinamici/RegolaEdit")
  );
  const RegolePrezziAdd = lazy(() =>
    import("./components/Circolo/RegolePrezziDinamici/RegolaAdd")
  );
  const ListaTabellaPrezzi = lazy(() =>
    import("./components/Circolo/TabellaPrezzi/ListaTabellaPrezzi")
  );
  const TabellaPrezzi = lazy(() =>
    import("./components/Circolo/TabellaPrezzi/TabellaPrezzi")
  );
  const TabellaPrezziNew = lazy(() =>
    import("./components/Circolo/TabellaPrezzi/New/TabellaPrezzi")
  );
  const TabellaStrutture = lazy(() =>
    import("./components/Pacchetti Vacanze/Strutture/Strutture")
  );
  const MailPrenotazione = lazy(() =>
    import("./components/Prenotazioni/MailPrenotazione")
  );
  const LeggiQRCODE = lazy(() =>
    import("./components/OperatoriTuristici/LeggiQRCODE")
  );
  const MailPrenotazioneServizio = lazy(() =>
    import("./components/PrenotazioniServizi/MailPrenotazioneServizio")
  );

  const StruttureAdd = lazy(() =>
    import("./components/Pacchetti Vacanze/Strutture/StrutturaAdd")
  );
  const StruttureEdit = lazy(() =>
    import("./components/Pacchetti Vacanze/Strutture/StrutturaEdit")
  );

  const TabellaPacchetti = lazy(() =>
    import("./components/Pacchetti Vacanze/Pacchetti/Pacchetti")
  );
  const PacchettoAdd = lazy(() =>
    import("./components/Pacchetti Vacanze/Pacchetti/PacchettoAdd")
  );
  const PacchettoEdit = lazy(() =>
    import("./components/Pacchetti Vacanze/Pacchetti/PacchettoEdit")
  );
  const ServizioEdit = lazy(() =>
    import("./components/OperatoriTuristici/ServizioEdit")
  );
  const ServizioAdd = lazy(() =>
    import("./components/OperatoriTuristici/ServizioAdd")
  );
  const PacchettoTest = lazy(() =>
    import("./components/Pacchetti Vacanze/Pacchetti/PacchettoSingolo")
  );

  const ServiziListing = lazy(() =>
    import("./components/OperatoriTuristici/ServiziTuristici")
  );

  const EsperienzeEsclusiveEdit = lazy(() =>
    import(
      "./components/OperatoriTuristici/EsperienzeEsclusive/EsperienzeEsclusiveEdit"
    )
  );
  const EsperienzeEsclusivePage = lazy(() =>
    import(
      "./components/OperatoriTuristici/EsperienzeEsclusive/EsperienzeEsclusivePage"
    )
  );

  const TourOperatorAnagrafica = lazy(() =>
    import("./components/TourOperator/TourOperatorAnagrafica")
  );
  const TourOperatorPage = lazy(() =>
    import("./components/TourOperator/TourOperatorPage")
  );

  const OperatoriListing = lazy(() =>
    import("./components/OperatoriTuristici/Operatori")
  );
  const OperatoreEdit = lazy(() =>
    import("./components/OperatoriTuristici/OperatoreEdit")
  );
  const Calendario = lazy(() =>
    import("./components/OperatoriTuristici/Calendario")
  );
  const Prenotazioni = lazy(() =>
    import("./components/Prenotazioni/PrenotazioniTable")
  );
  const ListaPrenotazioniVacanze = lazy(() =>
    import("./components/PrenotazioniVacanze/PrenotazioniVacanze")
  );
  const PrenotazioneVacanzaEdit = lazy(() =>
    import("./components/PrenotazioniVacanze/PrenotazioneVacanzaEdit")
  );
  const PrenotazioniServizi = lazy(() =>
    import("./components/PrenotazioniServizi/PrenotazioniServizi")
  );
  const PrenotazioneServiziEdit = lazy(() =>
    import("./components/PrenotazioniServizi/PrenotazioneServiziEdit")
  );

  const DashboardManager = lazy(() =>
    import("./components/common/DashboardManager")
  );
  const ActivateAccount = lazy(() =>
    import("./components/common/ActivateAccount")
  );

  const StatisticheCircoli = lazy(() =>
    import("./components/StatisticheCircoli/StatisticheCircoli")
  );

  const UtenteAdd = lazy(() =>
    import("./components/Amministrazione/UtenteAdd")
  );
  const UtenteEdit = lazy(() =>
    import("./components/Amministrazione/UtenteEdit")
  );
  const Utenti = lazy(() => import("./components/Amministrazione/Utenti"));
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="text-center py-5">
            <CircularProgress sx={{ color: "#A91354" }} />
          </div>
        }
      >
        <Switch>
          {!authCtx.isLoggedIn && (
            <Route exact path="/signup" component={RegistrationPage} />
          )}
          {/* <FakeHeader> */}
          {!authCtx.isLoggedIn && (
            <Route exact path="/login" component={LoginPage} />
          )}
          {!authCtx.isLoggedIn && (
            <Route exact path="/passwordreset" component={PasswordReset} />
          )}
          {!authCtx.isLoggedIn && (
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
          )}
          <Route exact path="/activate/:code" component={ActivateAccount} />
          {/* </FakeHeader> */}
          {authCtx.isLoggedIn && (
            <Wrapper>
              <Route exact path="/statistiche" component={Index} />
              <Route exact path="/wip" component={WorkingPage} />
              <Route exact path="/manage" component={ManagePage} />
              <Route
                exact
                path="/statistiche-circolo"
                component={StatisticheCircolo}
              />
              <Route exact path="/dummy-table" component={DummyTablePage} />
              <Route exact path="/info-circolo" component={Circolo} />

              <Route
                exact
                path="/tour_operator-edit"
                component={TourOperatorAnagrafica}
              />
              <Route exact path="/tour_operator" component={TourOperatorPage} />

                <Route exact path="/circoli" component={Circoli} />
            
              {authCtx.isLoggedIn && localStorage.getItem("idprofile") == 1 ? (
                <Route exact path="/login-as" component={LoginAs} />
              ) : null}
              {authCtx.isLoggedIn && localStorage.getItem("idprofile") == 1 ? (
                <Route
                  exact
                  path="/circolo-details"
                  component={CircoloDetailsEdit}
                />
              ) : null}

              <Route exact path="/circolo-edit" component={EditCircolo} />
              <Route exact path="/prodotti" component={Prodotti} />
              <Route
                exact
                path="/tipologia-giocatori"
                component={TipologiaGiocatori}
              />

              <Route exact path="/gare" component={GarePage} />
              <Route exact path="/gara-edit" component={GaraEdit} />
              <Route exact path="/gara-add" component={GaraEdit} />

              <Route exact path="/buy-greenfee" component={PrenotaGreenFee} />

              <Route
                exact
                path="/servizi-circolo"
                component={ServizioCircoloPage}
              />
              <Route
                exact
                path="/servizi-circolo-edit"
                component={ServizioCircoloEdit}
              />
              <Route
                exact
                path="/servizi-circolo-add"
                component={ServizioCircoloEdit}
              />

              <Route exact path="/cms-news" component={CMSNewsPage} />
              <Route exact path="/cms-news-edit" component={CMSNewsEdit} />
              <Route exact path="/cms-news-add" component={CMSNewsEdit} />

              <Route exact path="/cms-pages" component={CMSPagesPage} />
              <Route exact path="/cms-pages-edit" component={CMSPagesEdit} />
              <Route exact path="/cms-pages-add" component={CMSPagesEdit} />

              <Route exact path="/percorsi" component={Percorsi} />
              <Route exact path="/percorso-edit" component={PercorsoEdit} />
              <Route exact path="/percorso-add" component={PercorsoCreate} />
              <Route exact path="/prodotto-edit" component={ProdottoEdit} />
              <Route exact path="/prodotto-add" component={ProdottoAdd} />
              <Route exact path="/tipologia-edit" component={TipologiaEdit} />
              <Route exact path="/tipologia-add" component={TipologiaAdd} />
              <Route exact path="/regole-prezzi" component={PrezziDinamici} />
              <Route exact path="/regola-add" component={RegolePrezziAdd} />
              <Route exact path="/calendario" component={Calendario} />
              <Route
                exact
                path="/calendario-circolo"
                component={CalendarioCircolo}
              />
              <Route
                exact
                path="/calendario-nuovo"
                component={CalendarioNuovo}
              />

                <Route exact path="/statistiche-circoli" component={StatisticheCircoli} />
              

              <Route exact path="/" component={DashboardManager} />

              <Route exact path="/soci" component={Soci} />
              <Route exact path="/soci-edit" component={SociEdit} />
              <Route exact path="/soci-add" component={SociAdd} />
              <Route exact path="/soci-import" component={ImportaSoci} />

              <Route
                exact
                path="/regole-prezzi-edit"
                component={RegolePrezziEdit}
              />

              <Route
                exact
                path="/tabella-prezzi"
                component={ListaTabellaPrezzi}
              />
              <Route
                exact
                path="/tabella-prezzi-add"
                component={TabellaPrezzi}
              />
              <Route
                exact
                path="/tabella-prezzi-new"
                component={TabellaPrezziNew}
              />

              <Route
                exact
                path="/pacchetti-dinamici-add"
                component={PacchettiDinamiciDetailsEdit}
              />
              <Route
                exact
                path="/pacchetti-dinamici-edit"
                component={PacchettiDinamiciDetailsEdit}
              />
              <Route
                exact
                path="/pacchetti-dinamici"
                component={PacchettiDinamiciPage}
              />

              

              <Route
                exact
                path="/pacchetti-dinamici-adesioni/:uuid"
                component={PacchettiDinamiciAdesioniPage}
              />
              <Route
                exact
                path="/pacchetti-dinamici-adesioni-edit/:pacchettoID"
                component={PacchettiDinamiciAdesioniEdit}
              />

              <Route
                exact
                path="/tabella-strutture"
                component={TabellaStrutture}
              />
              <Route
                exact
                path="/tabella-pacchetti"
                component={TabellaPacchetti}
              />
              <Route
                exact
                path="/tabella-prenotazioni"
                component={Prenotazioni}
              />
              <Route
                exact
                path="/tabella-prenotazionivacanze"
                component={ListaPrenotazioniVacanze}
              />
              <Route
                exact
                path="/prenotazionevacanza-edit"
                component={PrenotazioneVacanzaEdit}
              />
              <Route
                exact
                path="/tabella-prenotazioniservizi"
                component={PrenotazioniServizi}
              />
              <Route
                exact
                path="/prenotazione-edit"
                component={PrenotazioneServiziEdit}
              />
              <Route exact path="/leggi-qrcode" component={LeggiQRCODE} />
              <Route
                exact
                path="/mail-prenotazione"
                component={MailPrenotazione}
              />
              <Route
                exact
                path="/mail-prenotazioneservizio"
                component={MailPrenotazioneServizio}
              />

<Route exact path="/reselled-dynamic-packages" component={ReselledDynamicPackages} />
<Route exact path="/resell-dynamic-packages" component={PacchettiDinamiciResellPage} />



              <Route exact path="/struttura-add" component={StruttureAdd} />
              <Route exact path="/struttura-edit" component={StruttureEdit} />
              <Route exact path="/pacchetto-add" component={PacchettoAdd} />
              <Route exact path="/pacchetto-test" component={PacchettoTest} />
              <Route exact path="/pacchetto-edit" component={PacchettoEdit} />
              <Route exact path="/servizio-edit" component={ServizioEdit} />
              <Route exact path="/servizio-add" component={ServizioAdd} />
              <Route exact path="/servizi-elenco" component={ServiziListing} />
              <Route exact path="/operatore-edit" component={OperatoreEdit} />
              <Route
                exact
                path="/anagrafica-touroperator"
                component={TourOperatorAnagrafica}
              />

              <Route
                exact
                path="/esperienze-esclusive"
                component={EsperienzeEsclusivePage}
              />
              <Route
                exact
                path="/esperienze-esclusive-edit"
                component={EsperienzeEsclusiveEdit}
              />

              <Route
                exact
                path="/operatori-elenco"
                component={OperatoriListing}
              />
              <Route exact path="/crea-utente" component={UtenteAdd} />
              <Route exact path="/modifica-utente" component={UtenteEdit} />
              <Route exact path="/lista-utenti" component={Utenti} />
            </Wrapper>
          )}
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <AuthContextProvider>
    <ComponentsContextProvider>
      <IntlProviderWrapper locale="en">
        <Root />
      </IntlProviderWrapper>
    </ComponentsContextProvider>
  </AuthContextProvider>,
  document.getElementById("root")
);

//serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
