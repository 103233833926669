import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { useCookies } from "react-cookie";
import { links } from "../data/headerData";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../store/auth-context";
import ComponentsContext from "../store/components-context";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import "../style/app.css";
import DinamicRepeaterMenu from "../elements/DinamicRepeaterMenu";
import Menu from "../elements/Menu";
import bestlogo from "../../images/logo_bestgolf_bianco_b2b.png";
import engwrap from "../../images/us.jpg";
import Dialog from "@mui/material/Dialog";
import Dialog2 from "@mui/material/Dialog";
import { styled, useTheme } from "@mui/material/styles";
import { Button, Menu as MUIMenu, MenuItem } from '@mui/material';
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useLocation } from "react-router";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  color: "#a71856",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft(props) {
  const location = useLocation();


  const [anchorEl, setAnchorEl] = React.useState(null);
  const openSubMenu = Boolean(anchorEl);

  const handleClickSubMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSubMenu = () => {
    setAnchorEl(null);
  };



  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [components, setComponents] = useState([]);
  const [golffunctions, setGolffunctions] = useState([]);
  const [adminfunctions, setAdminfunctions] = useState([]);
  const [ssidoperatore, setSsidoperatore] = useState([]);
  const [slugcampo, setSlugcampo] = useState([]);
  const [circoliabilitati, setCircoliabilitati] = useState(localStorage.getItem('circoli_abilitati') != null ? JSON.parse(localStorage.getItem('circoli_abilitati')) : []);
  const [tourismfunctions, setTourismfunctions] = useState([]);
  const [userfunctions, setUserfunctions] = useState([]);
  const [componentsloaded, setComponentsloaded] = useState(false);
  const [operatoreloaded, setOperatoreloaded] = useState(false);
  const [golfloaded, setGolfloaded] = useState(false);
  const [idprofile, setIdprofile] = useState(0);
  const [indexactive, setIndexactive] = useState("");
  const [descprofile, setDescprofile] = useState("");
  const [componentsNames, setComponentsNames] = useState([
    { component_name: "yoink", submenu: "yoink" },
    { component_name: "yoink", submenu: "yoink" },
  ]);
  const [componentsnamesloaded, setComponentsNamesloaded] = useState(false);
  const [namesHolder, setNamesHolder] = useState([]);
  const [namesloaded, setNamesloaded] = useState(false);
  const [menureloaded, setMenuReloaded] = useState(false);
  const [isStrutture, setIsStrutture] = useState([]);
  const [isCampi, setIsCampi] = useState([]);
  const [modal, setModal] = useState(false);
  const [alertmodal, setAlertModal] = useState(false);
  const [alertcolor, setAlertcolor] = useState(
    "text-center text-white bg-secondary"
  );
  const [resultdata, setResultdata] = useState();

  const compCtx = useContext(ComponentsContext);
  const authCtx = useContext(AuthContext);
  const [LocCookie, setLocCookie] = useCookies(['language']);
  const date_expires = new Date();
    date_expires.setDate(date_expires.getDate() + 999);

  var operatorcancreateservices = localStorage.getItem(
    "operatorcancreateservices"
  );
  var operatorcansellservices = localStorage.getItem("operatorcansellservices");
  let [dropdownLogin, setDropdownLogin] = useState(false);
  const history = useHistory();
  const autchCtx = useContext(AuthContext);



  const backToAdmin = () => {
    localStorage.removeItem('slugcampo');

            localStorage.removeItem('tipologia_giocatori');
            localStorage.removeItem('prodotti');
            localStorage.removeItem('percorsi');
            localStorage.removeItem('circolo_selezionato');

            history.push('/circoli');

  }
  const removeModalHandler = () => {
    setModal(false);
  };
  const removeAlertModalHandler = () => {
    setAlertModal(false);
  };
  const updateMenu = (indexactive) => {
    setIndexactive(indexactive);
  };
  useEffect(() => {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    setOpen(!check)
  }, [location.pathname]);

  useEffect(()=>{
    if (open)
      document.getElementById('root').classList.add("open");
    else
      document.getElementById('root').classList.remove("open");
  }, [open])

  const logOutHandler = () => {
    autchCtx.logout();
    history.push("/login");
  };

  const toggleLoginMenu = () => {
    alert("apro menu");
    setDropdownLogin(!dropdownLogin);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!componentsloaded) {
      var config = {
        method: "get",
        url: "https://api.bestime.golf/acl/reactcomponents",
        headers: {
          "Content-Type": "application/json",
          Bearer: authCtx.token,
        },
        //  data:data,
      };
      
      setResultdata(<FormattedMessage id="loading-menu" />);
      setAlertModal(true);
      axios(config)
        .then(function (response) {
          // autchCtx.login(response.data.data.access_token);
          console.log("da header " + JSON.stringify(response.data.data));
          console.log(authCtx);
          setComponents(response.data.data);
          setComponentsloaded(true);
          localStorage.setItem(
            "idprofile",
            response.data.data[0].id_profile_type
          );
          setIdprofile(response.data.data[0].id_profile_type);
		  if(response.data.data[0].id_profile_type===1){
			setDescprofile(<FormattedMessage id="administrator" />);
		  }
		  if(response.data.data[0].id_profile_type===2){
			setDescprofile(<FormattedMessage id="operator" />);
		  }
		  if(response.data.data[0].id_profile_type===3){
			setDescprofile(<FormattedMessage id="structure" />);
		  }
		  if(response.data.data[0].id_profile_type===4){
			setDescprofile(<FormattedMessage id="touroperator" />);
		  }
		  if(response.data.data[0].id_profile_type===6){
			setDescprofile(<FormattedMessage id="greenfeeuser" />);
		  }
		  if(response.data.data[0].id_profile_type===7){
			setDescprofile(<FormattedMessage id="user" />);
		  }
          setAlertModal(false);
        })
        .catch(function (error) {
          setAlertModal(false);
          console.log(error);
          console.log(config);
        });
    }
  }, [componentsloaded]);

  useEffect(()=>{
    if (localStorage.getItem('campi_abilitati') !== null && JSON.parse(localStorage.getItem('campi_abilitati')).length > 0) {
    let config = {
      method: 'post',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      url:`https://api.bestime.golf/golf/circoli/`,
      data:{"data":{"circoli":JSON.parse(localStorage.getItem('campi_abilitati'))}}
    };
    axios(config)
		      .then(function (response) {
            setCircoliabilitati(response.data.data);
            console.log(response.data.data, 'IMPOSTO CIRCOLO ABILITATI');
            localStorage.setItem("circoli_abilitati", JSON.stringify(response.data.data))
        
      })
      .catch((err) => {
       console.log(err)
      })
    }
  }, [localStorage.getItem('campi_abilitati')])


  useEffect(() => {
    if (authCtx.token && !componentsloaded) {
      console.log(authCtx.token);
      var data = JSON.stringify({
        access_token: authCtx.token,
        tabella: "tur_operatori",
      });
      var config = {
        method: "post",
        url: "https://api.bestime.golf/acl/userelements",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      setResultdata(<FormattedMessage id="loading-user-profile" />);
      setAlertModal(true);
      axios(config)
        .then(function (response) {
          console.log(
            "OPERATORI ASSOCIATI " + JSON.stringify(response.data.data)
          );
          if (response.data.data.length === 1) {
            setSsidoperatore(response.data.data[0].ssid_element);
            localStorage.setItem(
              "ssidoperatore",
              response.data.data[0].ssid_element
            );
          }
          setOperatoreloaded(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  const setNuovoCampo = (slugcm) => {
    setSlugcampo(slugcm);
    setAnchorEl(false);
    localStorage.setItem('slugcampo', slugcm);
    localStorage.removeItem('percorsi');
    localStorage.removeItem('prodotti');
    localStorage.removeItem('tipologia_giocatori');
    history.push('/calendario-circolo');
  }
  useEffect(() => {
    if (authCtx.token && !componentsloaded) {
      var data = JSON.stringify({
        access_token: authCtx.token,
        tabella: "circolo",
      });
      var config = {
        method: "post",
        url: "https://api.bestime.golf/acl/userelements",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          console.log("OPERATORI ASSOCIATI " + response.data.data);
          if (response.data.data.length === 1) {
            setSlugcampo(response.data.data[0].ssid_element);
            localStorage.setItem("slugcampo", response.data.data[0].ssid_element);
          }
          setGolfloaded(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (componentsloaded && !namesloaded) {
      console.log("AVAILABLE COMPONENTS" + JSON.stringify(components));
      var names = [];
      for (var i = 0; i < components.length; i++) {
        var val = components[i];
        if (!names.includes(val.component_name)) {
          names.push(val.component_name);
        }
      }
      setNamesHolder(names);
      setGolffunctions(components.filter((val) => val.submenu === "Golf"));
      setTourismfunctions(
        components.filter((val) => val.submenu === "Turismo")
      );
      setAdminfunctions(
        components.filter((val) => val.submenu === "Amministrazione")
      );

      setUserfunctions(components.filter((val) => val.submenu === "Utente"));
      console.log("AVAILABLE COMPONENT NAMES" + JSON.stringify(namesHolder));
      setIsStrutture(namesHolder.includes("/tabella-strutture"));
      setSsidoperatore(localStorage.getItem("ssidoperatore"));
      setSlugcampo(localStorage.getItem("slugcampo"));
      setNamesloaded(true);
      if (!menureloaded) {
        setMenuReloaded(true);
      }
    }
  }, [componentsloaded, namesloaded]);

  useEffect(()=> {
    console.log('CAMBIO SLUG CAMPO');
  }, [slugcampo])

  return (
    <React.Fragment>
      <Dialog onClose={removeModalHandler} open={modal}>
        <div>
          <p class={alertcolor}>{resultdata}</p>
        </div>
      </Dialog>
      <Dialog2 onClose={removeAlertModalHandler} open={alertmodal}>
        <div class={alertcolor}>
          <p class={alertcolor}>{resultdata}</p>
        </div>
      </Dialog2>
      <Box
        sx={{ display: "flex" }}
        style={{
          backgroundColor: "#a71856",
        }}
      >
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          style={{
            backgroundColor: "#a71856",
          }}
          className="d-flex"
        >
          <Toolbar
            style={{
              backgroundColor: "#a71856",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
              style={{
                color: "white",
              }}
            >
              <MenuIcon />
            </IconButton>
            <div
              className="d-flex "
              style={{
                backgroundColor: "#a71856",
                marginRight: "auto",
              }}
            >
              <div
                className="dropdown d-inline-block language-switch"
                style={{
                  backgroundColor: "#a71856",
                }}
              >
                <span className="logo-lg" style={{ marginRight: "auto" }}>
                  <img
                    src={bestlogo}
                    alt=""
                    style={{
                      width:"80pt"
                    }}
                  />
                </span>
              </div>
            </div>

            <div className="navbar-header ">
              
              <div className="d-flex justify-content-end">
              
              { circoliabilitati.length == 0 ? (<>
                <div 
                  className="dropdown d-inline-block language-switch"
                  style={{
                    backgroundColor: "#a71856",
                    align: "right",
                    maxHeight: 10,display:"none"
                  }}
                >
                  { LocCookie.language == "en" ? (<>
                  <button
                    type="button"
                    className="btn header-item waves-effect"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      backgroundColor: "#a71856", color:'#FFF'
                    }}
                    onClick={()=>{ setLocCookie('language', 'it', { path: "/", expires: date_expires}); }}
                  >
                   IT
                  </button>
                  </>) : (<>
                  <button
                    type="button"
                    className="btn header-item waves-effect"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      backgroundColor: "#a71856", color:'#FFF'
                    }}
                    onClick={()=>{ setLocCookie('language', 'en', { path: "/", expires: date_expires}); }}
                  >
                   EN
                  </button>
                  </>) }
                </div>
                </>) : null}

                <div
                  className="dropdown d-inline-block"
                  style={{
                    backgroundColor: "#a71856",
                  }}
                >
                  <button
                    type="button"
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      backgroundColor: "#a71856",
                    }}
                  >
                    <img
                      className="rounded-circle header-profile-user"
                      src={process.env.PUBLIC_URL + "/user.jfif"}
                      alt="Header Avatar"
                    />
                    <span
                      className="d-none d-xl-inline-block ms-1 fw-medium font-size-15 mr-2"
                      style={{
                        color: "#ffffff",paddingLeft:"10pt"
                      }}
                    >
                      { circoliabilitati.length == 0 ? descprofile : (<>
                        <Button
        id="basic-button"
        aria-controls={openSubMenu ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openSubMenu ? 'true' : undefined}
        onClick={handleClickSubMenu}
        style={{color:"#FFF"}}
      >  
        {circoliabilitati.map((element, elix) => {
           return (element.slug == localStorage.getItem("slugcampo")) ?  element.nome_circolo : '';
        })}
      </Button>
      <MUIMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openSubMenu}
        onClose={handleCloseSubMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {circoliabilitati.map((element, elix) => {
                          return (<>
                            <MenuItem onClick={()=>{ setNuovoCampo(element.slug); }}>{element.nome_circolo}</MenuItem>
                          </>)
          })}
        
      </MUIMenu>
                      
                        
                      </>) }
                    </span>
                    {/* <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i> */}
                  </button>
                  <button
                    type="button"
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      backgroundColor: "#a71856",
                    }}
                    onClick={logOutHandler}
                  >
                    <span
                      className="d-none d-xl-inline-block ms-1 fw-medium font-size-15 mr-2"
                      style={{
                        color: "#ffffff",
                      }}
                    >
                      Logout
                    </span>
                  </button>
                  <div></div>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          style={{
            backgroundColor: "#a71856",
          }}
          anchor="left"
          open={open}
        >
          <DrawerHeader className="navbar-brand-box">
            <IconButton
              onClick={handleDrawerClose}
              style={{
                backgroundColor: "#a71856",
                color: "white",
              }}
            >
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <div
            data-simplebar
            className="sidebar-menu-scroll"
            style={{
              backgroundColor: "#a71856",
            }}
          >
            <div id="sidebar-menu" className="sidebar-menu-scroll">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">
                  <span style={{ fontSize: "20px" }}>
                    <FormattedMessage id="menu" />
                  </span>
                </li>
                { /* idprofile == '6' ? null : (<>
                <li>
                
                  <ul className="sub-menu" aria-expanded="true">
                   <li>
                      <Link to={{ pathname: "/statistiche" }} onClick={() => setIndexactive("0stats")}>
                        <i className="fa fa-bar-chart fa-lg text-white" ></i>
					    <span style={{ fontSize: "14px",backgroundColor:indexactive!=="" && "0stats"===indexactive?"blue":""}}><FormattedMessage id="statistiche" /></span>
                      </Link>
                    </li> 
                  </ul>
                </>)
                </li>
 */ }
                <li>
                  <ul className="sub-menu pt-3" aria-expanded="true">
                    <li  style={{ paddingLeft:"20px" }}>
                        {adminfunctions.length > 0 ? (
                          <i className="fa fa-cogs fa-lg text-white"></i>
                        ) : (
                          ""
                        )}
                        {adminfunctions.length > 0 ? (
                          <span style={{ paddingLeft:"10px" ,fontSize: "20px",color:"white"  }}>
                            <FormattedMessage id="admin" />
                          </span>
                        ) : (
                          ""
                        )}
                    </li>
                    {adminfunctions.map((val, index) => (
                      <DinamicRepeaterMenu
                        index={index}
                        value={[val.component_name]}
                        min={[0]}
						indexactive={indexactive}
						submenu={"admin"}
						updateMenu={updateMenu}
                      />
                    ))}
                  </ul>
                  { idprofile == '6' ? null : (<>
                  <Divider />
                  </>) }
                  <ul className="sub-menu" aria-expanded="true" style={{paddingTop:"5px"}}>
                    { localStorage.getItem('idprofile') == 1 ? (<>
                      <li  style={{ paddingLeft:"20px" }}>
                        {golffunctions.length > 0 ? (
                          <i className="fa fa-golf-ball fa-lg text-white"></i>
                        ) : (
                          ""
                        )}
                        {golffunctions.length > 0 ? (
                          <span style={{ paddingLeft:"10px" ,fontSize: "20px",color:"white"  }}>
                            <FormattedMessage id="golf" />
                          </span>
                        ) : (
                          ""
                        )}
                        </li>
                        </>) : null}
                        <li style={{ paddingLeft:localStorage.getItem('idprofile') == 1 ? "35px" : '',paddingRight:localStorage.getItem('idprofile') == 1 ? "5px" : '' }}>
                    {golffunctions.map((val, index) => (
                      <DinamicRepeaterMenu
                        index={index}
                        value={[val.component_name]}
                        min={[0]}
						indexactive={indexactive}
						submenu={"golf"}
						updateMenu={updateMenu}
                      />
                    ))}
                    </li>
                  </ul>
                  <Divider />
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
	                  <ul className="sub-menu" aria-expanded="true">
	                    <li style={{ paddingLeft:"20px",paddingRight:"5px" }}>
                        {tourismfunctions.length > 0 ? (
                          <i className="fa fa-bed fa-lg text-white"></i>
                        ) : (
                          ""
                        )}
                        {tourismfunctions.length > 0 ? (
                          <span style={{ paddingLeft:"10px" ,fontSize: "20px",color:"white" }}>
                            <FormattedMessage id="tourism" />
                          </span>
                        ) : (
                          ""
                        )}
						</li>
							</ul>
                    </li>
                    {tourismfunctions.map((val, index) => (
                      <DinamicRepeaterMenu
                        index={index}
                        value={[val.component_name]}
                        min={[0]}
						indexactive={indexactive}
						submenu={"tourism"}
						updateMenu={updateMenu}
                      />
                    ))}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </Drawer>
      </Box>

      {localStorage.getItem('slugcampo') && localStorage.getItem('idprofile') == 1 ? (<>
        <div style={{position:'fixed', bottom:0, right:0, background:'#FFF', zIndex:'80', padding:'20px'}}>
          {localStorage.getItem('circolo_selezionato') ? (<>
            <p>{JSON.parse(localStorage.getItem('circolo_selezionato')).nome_circolo}</p>
            <ul style={{listStyle:'none', padding:0, margin:0 }}>
              <li><Link to="/calendario-circolo"><FormattedMessage id="calendario-circolo" /></Link></li>
              <li><Link to="/gare"><FormattedMessage id="gare" /></Link></li>
              <li><Link to="/percorsi"><FormattedMessage id="percorsi" /></Link></li>
              <li><Link to="/tipologia-giocatori"><FormattedMessage id="tipologia-giocatori" /></Link></li>
              <li><Link to="/prodotti"><FormattedMessage id="prodotti" /></Link></li>
              <li><Link to="/tabella-prezzi"><FormattedMessage id="tabella-prezzi" /></Link></li>
              <li><Link to="/regole-prezzi"><FormattedMessage id="regole-prezzi" /></Link></li>
              <li><Link to="/soci"><FormattedMessage id="soci" /></Link></li>
              <li><Link to="/servizi-circolo"><FormattedMessage id="servizi-circolo" /></Link></li>
              <li><Link to="/statistiche-circolo"><FormattedMessage id="statistiche-circolo" /></Link></li>
              <li style={{textAlign:'center', paddingTop:10}}><Button onClick={backToAdmin}  color="primary"
              variant="contained"  style={{backgroundColor: "#A91354"}} ><FormattedMessage id="back-to-admin" /></Button></li>
            </ul>
          </>) : null}
          
        </div>
      </>) : null }
    </React.Fragment>
  );
}
