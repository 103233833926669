import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { links } from "../data/headerData";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../store/auth-context";
import ComponentsContext from "../store/components-context";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import "../style/app.css";
import DinamicRepeaterMenu from "../elements/DinamicRepeaterMenu";
import Menu from "../elements/Menu"; 
import bestlogo from "../../images/logo1.png";
import engwrap from "../../images/us.jpg";
import Drawer from '@mui/material/Drawer';
const Header = (props) => {

  const [components, setComponents] = useState([]);
  const [golffunctions, setGolffunctions] = useState([]);
  var [ssidoperatore, setSsidoperatore] = useState([]);
  var [slugcampo, setSlugcampo] = useState([]);
  const [tourismfunctions, setTourismfunctions] = useState([]);
  const [userfunctions, setUserfunctions] = useState([]);
  const [componentsloaded, setComponentsloaded] = useState(false);
  const[operatoreloaded,setOperatoreloaded]=useState(false);
  const[golfloaded,setGolfloaded]=useState(false);
  const[idprofile,setIdprofile]=useState(0);
  const [componentsNames, setComponentsNames] = useState([
    { component_name: "yoink",submenu: "yoink" },
    { component_name: "yoink",submenu: "yoink" },
  ]);
  const [componentsnamesloaded, setComponentsNamesloaded] = useState(false);
  const [namesHolder, setNamesHolder] = useState([]);
  const [namesloaded, setNamesloaded] = useState(false);
  const [menureloaded, setMenuReloaded] = useState(false);
  const [isStrutture, setIsStrutture] = useState([]);
  const [isCampi, setIsCampi] = useState([]);

  const compCtx = useContext(ComponentsContext);
  const authCtx = useContext(AuthContext);
  var operatorcancreateservices=localStorage.getItem('operatorcancreateservices');
  var operatorcansellservices=localStorage.getItem('operatorcansellservices');
  useEffect(() => {
	if(!componentsloaded){

	    var config = {
	      method: "get",
	      url: "https://api.bestime.golf/acl/reactcomponents",
	      headers: {
	        "Content-Type": "application/json",
	        Bearer: authCtx.token,
	      },
	      //  data:data,
	    };
	    axios(config)
	      .then(function (response) {
	        // autchCtx.login(response.data.data.access_token);
	        console.log("da header "+JSON.stringify(response.data.data));
	        setComponents(response.data.data)
			setComponentsloaded(true);
			localStorage.setItem('idprofile',response.data.data[0].id_profile_type);
			setIdprofile(response.data.data[0].id_profile_type);
	      })
	      .catch(function (error) {
	        console.log(error);
	        console.log(config);
	      });
	}
  }, [componentsloaded]);

  useEffect(() => {
	if(authCtx.token && !componentsloaded){
		console.log(authCtx.token);
	  var data = JSON.stringify({
        "access_token": authCtx.token,
        "tabella":"tur_operatori"
      });
      var config = {
        method: "post",
        url: "https://api.bestime.golf/acl/userelements",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };	
	    axios(config)
	      .then(function (response) {
	        console.log("OPERATORI ASSOCIATI "+JSON.stringify(response.data.data));
			if(response.data.data.length===1){
				setSsidoperatore(response.data.data[0].ssid_element);
				localStorage.setItem('ssidoperatore',response.data.data[0].ssid_element);
			}
			setOperatoreloaded(true);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });

	  }
  }, []);
  useEffect(() => {
	if(authCtx.token && !componentsloaded){
	  var data = JSON.stringify({
        "access_token": authCtx.token,
        "tabella":"circolo"
      });
      var config = {
        method: "post",
        url: "https://api.bestime.golf/acl/userelements",
        headers: {
          "Content-Type": "application/json",
	        Bearer: authCtx.token,
        },
        data: data,
      };	
	    axios(config)
	      .then(function (response) {
	        console.log("OPERATORI ASSOCIATI "+response.data.data);
			if(response.data.data.length===1){
				setSlugcampo(response.data.data[0].ssid_element);
				localStorage.setItem('slugcampo',JSON.stringify(response.data.data[0].ssid_element).replaceAll('"', ''));
			}
			setGolfloaded(true);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });

	  }
  }, []);
  useEffect(() => {
    if (componentsloaded && !namesloaded) {
	console.log("AVAILABLE COMPONENTS"+JSON.stringify(components));
	  var names=[];
      for(var i=0; i<components.length;i++){
	    var val=components[i];
        if (!names.includes(val.component_name)) {
          names.push(val.component_name);
        }
      };
     setNamesHolder(names);
	  setGolffunctions(components.filter((val) =>val.submenu==="Golf"));
	  setTourismfunctions(components.filter((val) =>val.submenu==="Turismo"));
	  setUserfunctions(components.filter((val) =>val.submenu==="Utente"));
	  console.log("AVAILABLE COMPONENT NAMES"+JSON.stringify(namesHolder));
      setIsStrutture(namesHolder.includes("/tabella-strutture"));
      ssidoperatore=localStorage.getItem('ssidoperatore');
      slugcampo=localStorage.getItem('slugcampo');
	  setNamesloaded(true);
	   if(!menureloaded){
		setMenuReloaded(true);
		}
 	}
  }, [componentsloaded,namesloaded]);

  //////////////////////////////////////////////////

  //////////////////////////////////////////////////

  /////////////////////////////////////////////////

  // console.log("IS STRUTTURE", isStrutture);
  const history = useHistory();
  const autchCtx = useContext(AuthContext);
  const logOutHandler = () => {
    autchCtx.logout();
    history.replace("/login");
  };
  let [aperto, setAperto] = useState(true);
  let [apertoMobile, setApertoMobile] = useState(true);
  let [dropdownLogin, setDropdownLogin] = useState(false);

  const toggleLoginMenu = () => {
    setDropdownLogin(!dropdownLogin);
  };

  const toggleHamburger = () => {
    setAperto(!aperto);
  };

  const toggleHamburgerMobile = () => {
    setApertoMobile(!apertoMobile);
    console.log("clicato mobile");
  };

  const mobilecheck = function () {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

	if(check) setAperto(true)
    return check;
  };
  //max-width 992px  e  click su fa-bars-1  ?   ===> body => body-di-header-e-sidebar sidebar-enable : ""

  //padre-input-search > input  : d-none :  d-block
  // fa-bars-2 d-none

  return (
    <div
      className={
        "body-di-header-e-sidebar "
      }
    >
      <div id="layout-wrapper" className={aperto ? 'open' : ''}>
			<Menu open={aperto}/>

      </div>
    </div>
  );
};

export default Header;
